import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@environments/environment';
import * as sharedModels from '@shared/models';
import { CITY_KEY, IS_HIDE_CALL_CENTER_NUMBER, NUMBER_AGENT_KEY } from 'const';
import * as dm from 'generated/api/dm.api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, ReplaySubject, Subject, of } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@app/ngrx/app-state/app-state';
import {clearFilters, countFilters, updateFilter} from '@app/ngrx/filters/actions';
import { getCountFilters, getFiltersState } from '@app/ngrx/filters/states/filters-getters.state';

type GetSettingsByKeys = dm.paths['/open-api/settings/getByKeys/{keysList}'];
type SettingsByKeysParams = GetSettingsByKeys['get']['parameters']['path']['keysList'];
export type SettingsByKeysResp = GetSettingsByKeys['get']['responses']['200']['schema'];

@Injectable({
  providedIn: 'root',
})
export class SettingsService implements OnDestroy {
  private currentLang: sharedModels.LanguageList = sharedModels.LanguageList.RU;
  private currentLangAsKey: sharedModels.LanguageKeyList = sharedModels.LanguageKeyList.RU;

  #cityListSubject = new ReplaySubject<sharedModels.AddressObject[]>(1);

  private _currentCity$: Subject<sharedModels.AddressObject> = new Subject<sharedModels.AddressObject>();
  public currentCity$ = this._currentCity$.asObservable();
  public currentCity: sharedModels.AddressObject;

  #locationSubject = new ReplaySubject<{ country: sharedModels.AddressObject; location: sharedModels.AddressObject }>(1);
  public location$ = this.#locationSubject.asObservable();

  #changeLocationSubject: Subject<void> = new Subject<any>();
  public changeLocation$ = this.#changeLocationSubject.asObservable();

  #targetLocationSubject = new ReplaySubject<sharedModels.AddressObject>(1);
  public targetLocation$ = this.#targetLocationSubject.asObservable();

  modalRef: DynamicDialogRef;

  defaultCity: sharedModels.AddressObject;

  cyprusCity: sharedModels.AddressObject;

  private readonly mobileQuery: string[] = ['(max-width: 600px)', '(max-width: 1000px) and (max-height: 600px)']; // 599px
  private readonly tabletQuery = '(min-width: 600px) and (max-width: 1024px)';

  private readonly isMobile$: Observable<boolean>;
  private readonly isTablet$: Observable<boolean>;

  public clearComplex$: Subject<boolean> = new Subject<boolean>();
  public changePhoneNumber$: Subject<boolean> = new Subject<boolean>();

  public currentFilters$: Observable<any> = this.store.select(getFiltersState);
  public countFilters$: Observable<number> = this.store.select(getCountFilters);

  phonesMap = new Map();
  private destroy$ = new Subject<void>();

  constructor(
    private _http: HttpClient,
    private readonly breakpointObserver: BreakpointObserver,
    private store: Store<AppState>,
  ) {
    this.#cityListSubject.pipe(takeUntil(this.destroy$)).subscribe();
    this.isMobile$ = this.breakpointObserver.observe(this.mobileQuery).pipe(
      map(({ matches }: BreakpointState) => matches),
      shareReplay(1),
    );
    this.isTablet$ = this.breakpointObserver.observe(this.tabletQuery).pipe(
      map(({ matches }: BreakpointState) => matches),
      shareReplay(1),
    );

    this.phonesMap.set(661, '7712288822');
  }

  updateFilters(filters: any) {
    this.store.dispatch(updateFilter({ filters }));
  }

  updateFiltersCount(filters: any) {
    this.store.dispatch(countFilters({ filters }));
  }

  clearFilter() {
    this.store.dispatch(clearFilters());
  }

  getPhoneCallNumber() {
    return (SettingsService.city && this.phonesMap.get(SettingsService.city.id)) || '7057559595';
  }

  getCurrentLanguage(): sharedModels.LanguageList {
    return this.currentLang;
  }

  setCurrentLanguage(language: sharedModels.LanguageList): void {
    this.currentLang = language;
    this.setCurrentLanguageAsKey();
  }

  getCurrentLanguageAsKey(): 'nameEn' | 'nameKz' | 'nameRu' {
    const currentLang = this.currentLang.toUpperCase();
    return sharedModels.LanguageKeyList[currentLang as keyof sharedModels.LanguageList];
  }

  getCurrentShortLanguageAsKey(): string {
    const currentLang = this.currentLang.toUpperCase();
    return sharedModels.LanguageList[currentLang as keyof sharedModels.LanguageList];
  }

  setCurrentLanguageAsKey(): void {
    const currentLang = this.currentLang.toUpperCase();
    this.currentLangAsKey = sharedModels.LanguageKeyList[currentLang as keyof sharedModels.LanguageList];
  }

  setCurrentSelectedCity(city: sharedModels.AddressObject): void {
    this._currentCity$.next(city);
    this.currentCity = city;
    SettingsService.city = city;
  }

  public setCurrentSelectedCityById(id: number, cityList: sharedModels.AddressObject[]): Observable<sharedModels.AddressObject> {
    this.defaultCity = this.#findCityById(environment.appDefaultSettings.countries.kazakhstan.id, cityList);
    this.cyprusCity = this.#findCityById(environment.appDefaultSettings.countries.cyprus.id, cityList);
    return of(cityList).pipe(
      map((list) => list.find((city) => city.id === id)),
      map((city) => {
        if (city) {
          this.setCurrentSelectedCity(city);
          return city;
        } else {
          return null;
        }
      }),
    );
  }

  #findCityById(id: number, cityList: sharedModels.AddressObject[]): sharedModels.AddressObject {
    return cityList.find((city) => city.id === id);
  }

  getCurrentSelectedCity(): sharedModels.AddressObject {
    return this.currentCity;
  }

  setModal(modalRef: DynamicDialogRef): void {
    this.modalRef = modalRef;
  }

  getSettingsByKey(key: SettingsByKeysParams): Observable<SettingsByKeysResp> {
    return this._http.get<SettingsByKeysResp>(`${environment.apiDataManagerUrl}/open-api/settings/getByKeys/${key}`);
  }

  public setCityList(cityList: sharedModels.AddressObject[]): void {
    this.#cityListSubject.next(cityList);
  }

  static set city(city: sharedModels.AddressObject) {
    localStorage.setItem(CITY_KEY, JSON.stringify(city));
  }

  static get city(): sharedModels.AddressObject | null {
    let city = null;
    try {
      city = JSON.parse(localStorage.getItem(CITY_KEY));
    } catch (e) {
      city = { id: 1, code: '001001', name: 'Астана' };
      console.warn(e);
    }
    return city;
  }

  public setLocation(location: { country: sharedModels.AddressObject; location: sharedModels.AddressObject }): void {
    this.#locationSubject.next(location);
  }

  changePath() {
    this.#changeLocationSubject.next();
  }

  public setTargetLocation(location: sharedModels.AddressObject): void {
    // console.log('setTargetLocation', location);
    this.#targetLocationSubject.next(location);
  }

  public isMobile(): Observable<boolean> {
    return this.isMobile$;
  }

  public isTablet(): Observable<boolean> {
    return this.isTablet$;
  }

  public setNumberAgentSession(number: string) {
    sessionStorage.setItem(NUMBER_AGENT_KEY, number);
  }

  public get numberAgentSession() {
    return sessionStorage.getItem(NUMBER_AGENT_KEY);
  }

  setControlShowContact(isShow: boolean): void {
    sessionStorage.setItem(IS_HIDE_CALL_CENTER_NUMBER, JSON.stringify(isShow));
  }

  get isHideCallCenterNumber(): boolean {
    return JSON.parse(sessionStorage.getItem(IS_HIDE_CALL_CENTER_NUMBER));
  }

  static get country(): sharedModels.AddressObject {
    let country = null;

    try {
      country = JSON.parse(localStorage.getItem('country')) ?? null;
    } catch (e) {
      console.warn(e);
    }

    return country;
  }

  static set country(country) {
    localStorage.setItem('country', JSON.stringify(country));
  }

  static get location(): sharedModels.AddressObject {
    let location = null;

    try {
      location = JSON.parse(localStorage.getItem('location')) ?? null;
    } catch (e) {
      console.warn(e);
    }

    return location;
  }

  static set location(location) {
    localStorage.setItem('location', JSON.stringify(location));
  }

  public get locationTarget(): sharedModels.AddressObject {
    return JSON.parse('locationTarget');
  }

  static set locationTarget(locationTarget: sharedModels.AddressObject) {
    localStorage.setItem('locationTarget', JSON.stringify(locationTarget));
  }

  public clearFilters(isOnlyAddress = false) {
    this.clearComplex$.next(isOnlyAddress);
  }

  public changePhoneNumber(isShowNumber: boolean) {
    this.changePhoneNumber$.next(isShowNumber);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

import { cityReducer } from '@app/ngrx/city/reducers';
import { districtReducer } from '@app/ngrx/district/reducers';
import { countryReducer } from '@app/ngrx/country/reducers';
import { complexReducer } from '@app/ngrx/complex/reducers/complex.reducer';
import { feedbackReducer } from '@app/ngrx/feedback/reducers/feedback.reducer';
import { streetReducer } from '@app/ngrx/street/reducers';
import { filtersReducer } from '@app/ngrx/filters/reducers/filters.reducer';
import { realPropertyDataReducer } from '@app/ngrx/real-property-data/reducers';
import { dictionaryReducer } from '@app/ngrx/dictionary/reducers/dictionary.reducer';

import { CityEffects } from '@app/ngrx/city/effects';
import { ComplexEffects } from '@app/ngrx/complex/effects';
import { DistrictEffects } from '@app/ngrx/district/effects';
import { ICityState } from '@app/ngrx/city/states';
import { ICountryState } from '@app/ngrx/country/states';
import { IDistrictsState } from '@app/ngrx/district/states';
import { IComplexState } from '@app/ngrx/complex/states/complex.state';
import { IStreetState } from '@app/ngrx/street/states/street.state';
import { StreetEffects } from '@app/ngrx/street/effects';
import { IDictionaryState } from '@app/ngrx/dictionary/states/dictionary.state';
import { DictionaryEffects } from '@app/ngrx/dictionary/effects';
import { IFiltersState } from '@app/ngrx/filters/states/filters.state';
import { FeedbackEffects } from '@app/ngrx/feedback/effects';
import { IFeedbackState } from '@app/ngrx/feedback/states/feedback.state';
import { FiltersEffects } from '@app/ngrx/filters/effects/filters.effects';
import { RealPropertyDataEffects } from '@app/ngrx/real-property-data/effects';
import { IRealPropertyDataState } from '@app/ngrx/real-property-data/states/real-property-data.state';
import { IRealPropertyMapDataState } from '../real-property-data-map/states/real-property-data-map.state';
import { RealPropertyMapDataEffects } from '../real-property-data-map/effects';
import { realPropertyMapDataReducer } from '../real-property-data-map/reducers/real-property-data-map.reducer';

export interface AppState {
  readonly city: ICityState;
  readonly country: ICountryState;
  readonly district: IDistrictsState;
  readonly complex: IComplexState;
  readonly street: IStreetState;
  readonly dictionary: IDictionaryState;
  readonly feedback: IFeedbackState,
  readonly filters: IFiltersState;
  readonly realPropertyData: IRealPropertyDataState;
  readonly realPropertyMapData: IRealPropertyMapDataState;
}

export const reducers = {
  city: cityReducer,
  country: countryReducer,
  district: districtReducer,
  complex: complexReducer,
  street: streetReducer,
  dictionary: dictionaryReducer,
  feedback: feedbackReducer,
  filters: filtersReducer,
  realPropertyData: realPropertyDataReducer,
  realPropertyMapData: realPropertyMapDataReducer,
};

export const effects = [
  CityEffects,
  DistrictEffects,
  ComplexEffects,
  StreetEffects,
  DictionaryEffects,
  FeedbackEffects,
  FiltersEffects,
  RealPropertyDataEffects,
  RealPropertyMapDataEffects,
];

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import * as models from '../models';

@Injectable()
export class BookingService {
  constructor(private http: HttpClient) {}

  public addObjectBooking(obj: models.AddObjectBookingParams): Observable<models.ObjectBookingRes> {
    return this.http.post<models.ObjectBookingRes>(`${environment.apiDataManagerUrl}/open-api/applications-client/addObjectBooking`, obj);
  }

  public getRulesGuid(): Observable<models.Rules> {
    return this.http.get<models.Rules>(`${environment.apiDataManagerUrl}/open-api/settings/getByKeys/ca_rule_object_reservation`);
  }
}

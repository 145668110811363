import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@helpers/auth.guard';
const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@modules/main/main.module').then((m) => m.MainModule),
  },
  {
    path: 'home',
    redirectTo: '',
  },
  {
    path: 'real-property/:id',
    loadChildren: () => import('@modules/real-property-details/real-property.module').then((m) => m.RealPropertyModule),
  },
  {
    path: 'residential-complex/:id',
    loadChildren: () => import('@modules/residential-complex-details/residential-complex-details.module').then((m) => m.ResidentialComplexDetailsModule),
  },
  {
    path: 'real-property-agent/:id',
    loadChildren: () => import('@modules/real-property-agent-details/real-property-agent-details.module').then((m) => m.RealPropertyAgentDetailsModule),
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('@pages/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'account',
    canActivate: [AuthGuard],
    loadChildren: () => import('@modules/account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'contract',
    loadChildren: () => import('@modules/electronic-signing/electronic-signing.module').then((m) => m.ElectronicSigningModule),
  },
  {
    path: 'my-application-list',
    loadChildren: () => import('@modules/my-application-list/my-application-list.module').then((m) => m.MyApplicationListModule),
  },
  {
    path: 'support-request',
    loadChildren: () => import('@modules/support-request/support-request.module').then((m) => m.SupportRequestModule),
  },
  {
    path: 'favourites',
    loadChildren: () => import('@modules/favourite-real-property-list/favourite-real-property-list.module').then((m) => m.FavouriteRealPropertyListModule),
  },
  {
    path: 'search-settings',
    loadChildren: () => import('@modules/real-property-search-settings/real-property-search-settings.module').then((m) => m.RealPropertySearchSettingsModule),
  },
  {
    path: 'our-specialists',
    loadChildren: () => import('@modules/our-specialists/our-specialists.module').then((m) => m.OurSpecialistsModule),
  },
  {
    path: 'about',
    loadChildren: () => import('@app/about/about.module').then((m) => m.AboutModule),
  },
  // TODO: на время разработки
  {
    path: 'auth',
    loadChildren: () => import('@pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'new-design',
    loadChildren: () => import('@app/shell/shell.module').then((m) => m.ShellModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, preloadingStrategy: PreloadAllModules, initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

import * as models from '../models';

@Injectable()
export class SignUpShowService {
  constructor(private http: HttpClient) {}

  public createClientEvent(body: models.CreateClientEventParams): Observable<models.ClientEvent> {
    return this.http.post<models.ClientEvent>(`${environment.apiDataManagerUrl}/open-api/api/events/createClientEvent`, body);
  }
}
